"use client";
import { useEffect } from "react";
import CssMinifierTool from "../Components/css-minifier/css-minifiertool";
import setMeta from "../setMeta";
import HomeFeaturesSection from "../Components/Home/features";

 
// Marking this component as a Client Component
 const CSSMinifier = () => { 

  useEffect(() => {
    setMeta('CSSMinifier'); // Set meta for this specific page
}, []);

  return (
    
     <>
    <CssMinifierTool/>

    <section id="css-minifier" className="py-20 bg-gray-100 [&_a]:text-primary hover:[&_a]:text-secondary">
  <div className="container mx-auto">
    <h2 className="mb-6">CSS Minifier</h2>
    
    <p className="mb-4">
      Optimize your CSS files by reducing their size with our CSS Minifier tool. This tool removes unnecessary whitespace, comments, and other extraneous elements, leading to faster load times and improved performance.
    </p>

    <h3 className="text-xl font-semibold mt-6 mb-4">How It Works</h3>
    <ol className="list-decimal list-inside mb-4">
      <li><strong>Input Your CSS Code</strong>: Paste your CSS code into the designated input area.</li>
      <li><strong>Select Minification Options</strong>: Customize the minification process by choosing options like removing comments and whitespace.</li>
      <li><strong>Download Your Minified CSS</strong>: Once the minification is complete, download the optimized CSS file with a single click.</li>
    </ol>

    <h3 className="text-xl font-semibold mt-6 mb-4">Key Features</h3>
    <ul className="list-disc list-inside mb-4">
      <li><strong>Efficient Minification</strong>: Quickly reduces the size of your CSS files while preserving functionality.</li>
      <li><strong>Customizable Settings</strong>: Tailor the minification process to your specific requirements.</li>
      <li><strong>Fast Processing</strong>: Enjoy rapid minification times, even for large CSS files.</li>
      <li><strong>Preview Before Download</strong>: Check your minified CSS to ensure it meets your expectations before saving it.</li>
      <li><strong>Privacy Focused</strong>: Your data is processed securely and removed from our servers immediately after completion.</li>
    </ul>

    <h3 className="text-xl font-semibold mt-6 mb-4">Benefits of CSS Minification</h3>
    <ul className="list-disc list-inside mb-4">
      <li><strong>Faster Load Times</strong>: Smaller CSS files lead to quicker loading times, enhancing user experience.</li>
      <li><strong>Better SEO Performance</strong>: Improved page speed can contribute to better search engine rankings.</li>
      <li><strong>Reduced Bandwidth Usage</strong>: Minified CSS files use less bandwidth, which is crucial for high-traffic websites.</li>
      <li><strong>Improved Code Maintainability</strong>: Minifying helps keep your CSS efficient and manageable.</li>
    </ul>

    <h3 className="text-xl font-semibold mt-6 mb-4">Get Started</h3>
    <p>
      Ready to optimize your CSS files? <a title="Try our CSS Minifier" href="#hero">Try our CSS Minifier</a> today and experience the speed and efficiency improvements it brings to your web pages!
    </p>
  </div>
</section>
<HomeFeaturesSection/>  
     </>
  );
};

export default CSSMinifier;