import React, { useEffect } from 'react'; 
import setMeta from '../setMeta';
import PxToRemConverter from '../Components/pxToremTool/pxToremTool';
import HomeFeaturesSection from '../Components/Home/features';

export default function PxTorem() {   
    useEffect(() => {
      setMeta('pxToRemConverter'); // Set meta for this specific page
    }, []);

    return (
      <>   
      <PxToRemConverter/>
      <section id="px-to-rem-converter" className="py-20 bg-gray-100 [&_a]:text-primary hover:[&_a]:text-secondary">
  <div className="container mx-auto">
    <h2 className="mb-6">Px to Rem Converter</h2>
    
    <p className="mb-4">
      Convert pixel values to rem units easily with the Px to Rem Converter. This tool helps you maintain scalable typography and layout consistency by translating fixed pixel values into rem units based on a base font size.
    </p>

    <h3 className="text-xl font-semibold mt-6 mb-4">How It Works</h3>
    <ol className="list-decimal list-inside mb-4">
      <li><strong>Input Your Pixel Value:</strong> Enter the pixel value you wish to convert, and specify the base font size.</li>
      <li><strong>Automatic Calculation:</strong> The tool instantly converts the pixel value to rem units, using the provided base font size for accuracy.</li>
      <li><strong>Copy the Result:</strong> Once you have your rem value, simply copy it and incorporate it into your CSS for responsive typography.</li>
    </ol>

    <h3 className="text-xl font-semibold mt-6 mb-4">Key Features</h3>
    <ul className="list-disc list-inside mb-4">
      <li><strong>Customizable Base Font Size:</strong> Set your preferred base font size for tailored rem conversions that fit your design.</li>
      <li><strong>Precision and Accuracy:</strong> The converter uses accurate calculations to ensure your rem values are reliable for scalable typography.</li>
      <li><strong>Instant Results:</strong> Get real-time conversions as you type, making your workflow faster and more efficient.</li>
      <li><strong>Simple Interface:</strong> Easily input values and get conversions without any unnecessary complexity.</li>
    </ul>

    <h3 className="text-xl font-semibold mt-6 mb-4">Benefits of Using Rem Units</h3>
    <ul className="list-disc list-inside mb-4">
      <li><strong>Scalable Typography:</strong> Rem units allow for flexible font sizing, adapting across various devices for enhanced readability.</li>
      <li><strong>Consistency:</strong> Maintain consistent proportions in your design as rem units scale with the base font size.</li>
      <li><strong>Accessibility:</strong> Users can adjust their base font size preferences, improving accessibility and readability.</li>
      <li><strong>Reduced Complexity:</strong> Using rem units simplifies your CSS, reducing the need for multiple media queries.</li>
    </ul>

    <h3 className="text-xl font-semibold mt-6 mb-4">Get Started</h3>
    <p>
      Ready to enhance your design with scalable typography? <a title="Try our Px to Rem Converter" href="#hero">Try our Px to Rem Converter</a> today and make responsive design effortless!
    </p>
  </div>
</section>
<HomeFeaturesSection/>  

      </>
    );
}
