"use client"; // Marking this component as a Client Component

import React, { useState, useEffect } from 'react';
import { Loader } from '../Common/Loader';

const formatJavaScript = (jsCode) => {
  try {
    const formatted = jsCode
      .split('\n')
      .map(line => line.trim())
      .join('\n')
      .replace(/([{};])/g, '$1\n') // Adds line breaks after certain symbols
      .replace(/\n\s*\n/g, '\n'); // Removes excess line breaks
    return formatted.trim();
  } catch (error) {
    console.error("Error formatting JavaScript:", error);
    throw new Error("Formatting error.");
  }
};

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(handler);
  }, [value, delay]);

  return debouncedValue;
};

const JavascriptFormatterTool = () => {
  const [inputJs, setInputJs] = useState('');
  const [formattedJs, setFormattedJs] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const debouncedJs = useDebounce(inputJs, 500);

  useEffect(() => {
    if (debouncedJs) {
      setIsLoading(true);
      setError(''); // Clear any previous errors

      try {
        const formatted = formatJavaScript(debouncedJs);
        setFormattedJs(formatted);
      } catch (err) {
        setError('Error formatting JavaScript. Please check your input.');
        setFormattedJs(''); // Clear the formatted JavaScript on error
      } finally {
        setIsLoading(false);
      }
    } else {
      setFormattedJs(''); // Clear formatted JS if input is empty
    }
  }, [debouncedJs]);

  const handleClear = () => {
    setInputJs('');
    setFormattedJs('');
    setError('');
  };

  const handleCopy = () => {
    if (formattedJs) {
      navigator.clipboard.writeText(formattedJs)
        .then(() => {
          setShowAlert(true);
          setTimeout(() => setShowAlert(false), 2000); // Hide after 2 seconds
        })
        .catch((err) => {
          console.error('Failed to copy text:', err);
        });
    }
  };

  return (
    <section className='w-full' id='hero'>
      <div className="container">
        <div className="p-4 my-10">
          <div className='row'>
            <div className='lg:w-6/12 w-full px-5'>
              <h1 className="font-bold mb-10">JavaScript Formatter</h1>
              <textarea
                rows={10}
                className="w-full p-2 border border-gray-300 rounded-md mb-4 focus:outline-none focus:ring-2 focus:ring-offset-gray-800 min-h-[500px]"
                value={inputJs}
                onChange={(e) => setInputJs(e.target.value)}
                placeholder="Enter JavaScript code"
              />
              {error && <p className="text-[red] mt-2">{error}</p>}
              <button onClick={handleClear} className="primary-btn mt-5 btn-large">
                Clear
              </button>
            </div>

            <div className='lg:w-6/12 w-full px-5'>
              <h3 className="lg:mt-0 mt-10 mb-10 h2">Formatted JavaScript</h3>
              {isLoading ? <Loader /> : (
                <>
                  <textarea
                    rows={10}
                    className="w-full p-2 border border-gray-300 rounded-md mb-4 focus:outline-none focus:ring-2 focus:ring-offset-gray-800 min-h-[500px]"
                    value={formattedJs}
                    readOnly
                    placeholder="Formatted JavaScript will appear here..."
                  />
                  <button onClick={handleCopy} className="primary-btn mt-5 btn-large">
                    Copy
                  </button>
                </>
              )}
            </div>
          </div>
        </div>

        {/* Copy Alert */}
        {showAlert && (
          <div className="fixed bottom-4 right-4 bg-gray-900 text-white px-4 py-2 rounded shadow-lg transition-opacity duration-500 ease-out opacity-100 animate-fade-out">
            Formatted JavaScript copied to clipboard!
          </div>
        )}
      </div>
    </section>
  );
};

export default JavascriptFormatterTool;
