import React, { useState, useEffect } from 'react'; 
import { Loader } from '../Common/Loader'; 
import { BsCopy } from "react-icons/bs";

const PxToVwConverterTool = () => {
  const [inputPx, setInputPx] = useState('');
  const [viewportWidth, setViewportWidth] = useState(1920);
  const [convertedVw, setConvertedVw] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [copySuccess, setCopySuccess] = useState(''); // For copy success message

  const convertPxToVw = (px, vwWidth) => {
    const result = (px / vwWidth) * 100;
    return result % 1 === 0 ? result.toFixed(0) : result.toFixed(2); // Check if the result is an integer
  };

  useEffect(() => {
    const pxValue = parseFloat(inputPx);
    if (!isNaN(pxValue) && pxValue >= 0) {
      setLoading(true); // Start loading
      const vwValue = convertPxToVw(pxValue, viewportWidth);
      setConvertedVw(`${vwValue}vw`); // Append 'vw' to the converted value
      setError('');
      setLoading(false); // End loading
    } else {
      setConvertedVw('');
      if (inputPx) {
        setError('Please enter a valid positive pixel value.');
      }
    }
  }, [inputPx, viewportWidth]);

  const handleClear = () => {
    setInputPx('');
    setConvertedVw('');
    setError('');
    setCopySuccess(''); // Reset copy success message
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(convertedVw).then(() => {
      setCopySuccess('Copied successfully!');
      setTimeout(() => setCopySuccess(''), 2000); // Clear the message after 2 seconds
    });
  };

  return (
    <div className='container pb-5 text-center my-16' id='hero'>   

      <h1> PX to VW Converter  </h1>

      <div className="bg-blue-100 p-4 rounded-lg shadow-lg mb-6">
        <h2 className="text-xl font-bold mb-2">Viewport Width (default is 1920px)</h2>
        <input
          type="number"
          value={viewportWidth}
          onChange={(e) => setViewportWidth(parseFloat(e.target.value))}
          className="w-full p-3 border border-blue-300 rounded-md"
          placeholder="Enter viewport width"
        />
      </div>

      <div className="p-4 bg-white rounded-lg shadow-lg">
        <h1 className="text-2xl font-bold mb-4">px to vw Converter</h1>

        <textarea
          rows={2}
          className="w-full p-2 border border-gray-300 rounded-md mb-4"
          value={inputPx}
          onChange={(e) => setInputPx(e.target.value)}
          placeholder="Enter pixel value (e.g., 16)"
        />

        <button 
          onClick={handleClear} 
          className="w-full primary-btn mt-5 btn-large mb-4"
        >
          Clear
        </button>

        {error && <p className="text-red-500 mt-2">{error}</p>}

        {loading ? (
          <Loader /> // Show Loader while loading
        ) : (
          <>
            <h2 className="text-xl font-semibold mt-4">Converted vw Value</h2>
            <div className="flex items-center mb-4">
              <textarea
                rows={2}
                className="w-full p-2 border border-gray-300 rounded-md"
                value={convertedVw}
                readOnly
                placeholder="Converted vw will appear here..."
              />
              <button 
                className="m-2"
                onClick={handleCopy}
                disabled={!convertedVw}
              >
           <BsCopy />
              </button>
            </div>

            {copySuccess &&  <div className="fixed bottom-4 right-4 bg-gray-900 text-white px-4 py-2 rounded shadow-lg transition-opacity duration-500 ease-out opacity-100 animate-fade-out"> {copySuccess}</div>} {/* Show copy success message */}
          </>
        )}
      </div>
    </div>
  );
};

export default PxToVwConverterTool;
