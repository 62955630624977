"use client";

import React, { useState, useEffect } from 'react';
import { Loader } from '../Common/Loader';
import { BsCopy } from 'react-icons/bs';

const PxToRemConverter = () => {
  const [inputPx, setInputPx] = useState('');
  const [baseFontSize, setBaseFontSize] = useState(16);
  const [convertedRem, setConvertedRem] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false); // New loading state
  const [copySuccess, setCopySuccess] = useState(''); // For copy success message

  const convertPxToRem = (px, baseSize) => {
    return px / baseSize;
  };

  const formatRemValue = (rem) => {
    return rem.toFixed(4).replace(/\.?0+$/, ''); // Format rem value
  };

  useEffect(() => {
    const pxValue = parseFloat(inputPx);
    if (!isNaN(pxValue) && pxValue >= 0) {
      setLoading(true); // Start loading
      const remValue = convertPxToRem(pxValue, baseFontSize);
      setConvertedRem(`${formatRemValue(remValue)}rem`); // Add 'rem' unit
      setError('');
      setLoading(false); // End loading
    } else {
      setConvertedRem('');
      if (inputPx) {
        setError('Please enter a valid positive pixel value.');
      }
    }
  }, [inputPx, baseFontSize]);

  const handleClear = () => {
    setInputPx('');
    setConvertedRem('');
    setError('');
    setCopySuccess(''); // Reset copy success message
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(convertedRem).then(() => {
      setCopySuccess('Copied successfully!');
      setTimeout(() => setCopySuccess(''), 2000); // Clear the message after 2 seconds
    });
  };

  return (
    <div className='container pb-5 text-center my-16' id="hero">
       <h1> PX to Rem Converter  </h1>
      <div className="bg-blue-100 p-4 rounded-lg shadow-lg mb-6">
        <h2 className="text-xl font-bold mb-2">Base Font Size (default is 16px)</h2>
        <input
          type="number"
          value={baseFontSize}
          onChange={(e) => setBaseFontSize(parseFloat(e.target.value))}
          className="w-full p-3 border border-blue-300 rounded-md"
          placeholder="Enter base font size"
        />
      </div>

      <div className="p-4 bg-white rounded-lg shadow-lg">
        <h1 className="text-2xl font-bold mb-4">px to rem Converter</h1>
        
        <textarea
          rows={2}
          className="w-full p-2 border border-gray-300 rounded-md mb-4"
          value={inputPx}
          onChange={(e) => setInputPx(e.target.value)}
          placeholder="Enter pixel value (e.g., 240)"
        />

        <button 
          onClick={handleClear} 
          className="w-full primary-btn mt-5 btn-large mb-4"
        >
          Clear
        </button>

        {error && <p className="text-red-500 mt-2">{error}</p>}

        {loading ? (
          <Loader /> // Display Loader while loading
        ) : (
          <>
            <h2 className="text-xl font-semibold mt-4">Converted rem Value</h2>
            <div className="flex items-center mb-4">
              <textarea
                rows={2}
                className="w-full p-2 border border-gray-300 rounded-md"
                value={convertedRem}
                readOnly
                placeholder="Converted rem will appear here..."
              />
              <button 
                className="m-2"
                onClick={handleCopy}
                disabled={!convertedRem}
              >
                <BsCopy/>
              </button>
            </div>

            {copySuccess &&  <div className="fixed bottom-4 right-4 bg-gray-900 text-white px-4 py-2 rounded shadow-lg transition-opacity duration-500 ease-out opacity-100 animate-fade-out"> {copySuccess}</div>}
          </>
        )}
      </div>
    </div>
  );
};

export default PxToRemConverter;
