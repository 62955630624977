import React, { useState, useEffect } from 'react';
import { Loader } from '../Common/Loader'; // Updated import for the Loader component

const CssMinifierTool = () => {
  const [inputCss, setInputCss] = useState('');
  const [minifiedCss, setMinifiedCss] = useState('');
  const [keepComments, setKeepComments] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const minifyCss = (css, keepComments) => {
    if (keepComments) {
      return css.replace(/\s+/g, ' ').replace(/;}/g, '}').trim();
    }
    return css.replace(/\/\*[\s\S]*?\*\//g, '').replace(/\s+/g, ' ').replace(/;}/g, '}').trim();
  };

  useEffect(() => {
    const handleMinify = () => {
      setIsLoading(true);
      setError('');

      try {
        const minified = minifyCss(inputCss, keepComments);
        setMinifiedCss(minified);
      } catch (err) {
        setError('An error occurred during minification: ' + (err.message || 'Unknown error'));
      } finally {
        setIsLoading(false);
      }
    };

    handleMinify();
  }, [inputCss, keepComments]);

  const handleClear = () => {
    setInputCss('');
    setMinifiedCss('');
    setError('');
    setShowAlert(false);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(minifiedCss);
    setShowAlert(true);
    setTimeout(() => setShowAlert(false), 3000); // Hide alert after 3 seconds
  };

  return (
    <section id="hero" className='w-full'>
    <div className="container">
      <div className="p-4 my-10">
        <div className='row'>
          <div className='lg:w-6/12 w-full px-5'>
          <h1 className="font-bold mb-10">CSS minifier</h1>
            <textarea
              rows={10}
              className="w-full p-2 border border-gray-300 rounded-md mb-4 focus:outline-none focus:ring-2 focus:ring-offset-gray-800 min-h-[500px]"
              value={inputCss}
              onChange={(e) => setInputCss(e.target.value)}
              placeholder="Enter CSS code"
            />
            <div className="flex items-center mb-4">
              <input
                type="checkbox"
                id="keepComments"
                checked={keepComments}
                onChange={(e) => setKeepComments(e.target.checked)}
                className="mr-2"
              />
              <label htmlFor="keepComments" className="text-sm">
                Keep comments in minified CSS
              </label>
            </div>
            {error && <p className="text-red-500 mt-2">{error}</p>}
            <button onClick={handleClear} className="primary-btn mt-5 btn-large">
              Clear
            </button>
          </div>

          <div className='lg:w-6/12 w-full px-5'>
          <h2 className="font-bold mb-10">Minified CSS</h2>
            {isLoading ? <Loader /> : (
              <>
                <textarea
                  rows={10}
                  className="w-full p-2 border border-gray-300 rounded-md mb-4 focus:outline-none focus:ring-2 focus:ring-offset-gray-800 min-h-[500px]"
                  value={minifiedCss}
                  readOnly
                  placeholder="Minified CSS will appear here..."
                />
                <button onClick={handleCopy} className="primary-btn mt-5 btn-large">
                  Copy
                </button>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Copy Alert */}
      {showAlert && (
        <div className="fixed bottom-4 right-4 bg-gray-900 text-white px-4 py-2 rounded shadow-lg transition-opacity duration-500 ease-out opacity-100 animate-fade-out">
          Minified CSS copied to clipboard!
        </div>
      )}
    </div>
    </section>
  );
};

export default CssMinifierTool;
