"use client"; // Ensure this is added if needed in your project

import React, { useEffect } from 'react'; 
import HtmlMinifierTool from '../Components/html-minifier/html-minifier';
import setMeta from '../setMeta';
import HomeFeaturesSection from '../Components/Home/features';

const HtmlMinifier = () => {

  useEffect(() => {
    setMeta('HtmlMinifier'); // Set meta for this specific page
}, []);
  
  return (
    <>
    <HtmlMinifierTool/>
    <section id="html-minifier" className="py-20 bg-gray-100 [&_a]:text-primary hover:[&_a]:text-secondary">
  <div className="container mx-auto">
    <h2 className="mb-6">html code minifier</h2>
    
    <p className="mb-4">
      Optimize your web pages by reducing the size of your HTML files with our HTML Minifier tool. This tool removes unnecessary whitespace, comments, and other extraneous elements, improving load times without sacrificing functionality.
    </p>

    <h3 className="text-xl font-semibold mt-6 mb-4">How It Works</h3>
    <ol className="list-decimal list-inside mb-4">
      <li><strong>Input Your HTML Code</strong>: Paste your HTML code into the designated input area.</li>
      <li><strong>Select Minification Options</strong>: Choose from options like removing comments, whitespace, and optional attributes to customize your minification process.</li>
      <li><strong>Download Your Minified HTML</strong>: Once the minification is complete, download the optimized HTML file with a single click.</li>
    </ol>

    <h3 className="text-xl font-semibold mt-6 mb-4">Key Features</h3>
    <ul className="list-disc list-inside mb-4">
      <li><strong>Efficient Minification</strong>: Quickly reduces the size of your HTML files while maintaining functionality.</li>
      <li><strong>Customizable Settings</strong>: Tailor the minification process to your specific needs with various options.</li>
      <li><strong>Fast Processing</strong>: Enjoy rapid minification times, even for large HTML files.</li>
      <li><strong>Preview Before Download</strong>: Review your minified HTML code to ensure it meets your expectations before saving it.</li>
      <li><strong>Privacy Focused</strong>: Your data is processed securely and removed from our servers immediately after the process is complete.</li>
    </ul>

    <h3 className="text-xl font-semibold mt-6 mb-4">Benefits of HTML Minification</h3>
    <ul className="list-disc list-inside mb-4">
      <li><strong>Faster Load Times</strong>: Smaller HTML files lead to quicker loading times, enhancing user experience.</li>
      <li><strong>Better SEO Performance</strong>: Improved page speed can contribute to better search engine rankings.</li>
      <li><strong>Reduced Bandwidth Usage</strong>: Minified HTML files use less bandwidth, which is especially beneficial for high-traffic websites.</li>
      <li><strong>Improved Code Maintainability</strong>: Minifying can help ensure that your code remains efficient and easy to manage.</li>
    </ul>

    <h3 className="text-xl font-semibold mt-6 mb-4">Get Started</h3>
    <p>
      Ready to optimize your HTML files? <a href='#hero' title='Try our HTML Minifier'>Try our HTML Minifier</a> today and experience the speed and efficiency improvements it brings to your web pages!
    </p>
  </div>
</section>
<HomeFeaturesSection/>  
    </>
  );
};

export default HtmlMinifier;
