// src/metaConfig.js
import logoImage from './logo.png'; // Import the SVG logo

export const metaConfig = {
    home: { 
        title: 'Tools For Dev: Essential Web Development Tools',
        description: 'Explore Tools For Dev, featuring essential tools like image compressors, HTML and CSS formatters, and minifiers to streamline web development workflows and boost productivity.',
        keywords: 'web development tools, image compressor, HTML formatter, CSS minifier, developer tools, streamline workflows',
        robots: 'index, follow',
        author: 'Tools for Web Team',
        ogImage: logoImage, // Use the imported logo.svg for Open Graph
        canonical : "https://toolsfordev.com/",
    },
    tools: { 
        title: 'Tools For Dev: Essential Web Development Tools',
        description: 'Discover a suite of essential web development tools designed to enhance productivity and streamline workflows, including powerful image compressors, HTML formatters, CSS minifiers, and more.',
        keywords: 'web development tools, productivity tools, image compressor, HTML formatter, CSS minifier, developer tools, streamline workflow',
        robots: 'index, follow',
        author: 'Tools for Web Team',
        ogImage: logoImage, // Use the imported logo.svg for Open Graph
        canonical : "https://toolsfordev.com/tools",
    },    
    ImageCompressor: {
        title: 'Image Compressor: Optimize Your Images with Tools For Dev',
        description: 'Easily compress images with our user-friendly image compressor tool, optimizing your website performance and ensuring fast load times for your users.',
        keywords: 'image compressor, online image compressor, image compressor online, image compressor free, free image compressor, bulk image compressor, png image compressor',
        robots: 'index, follow',
        author: 'Tools for Web Team',
        ogImage: logoImage, // Use the imported logo.svg for Open Graph
        canonical : "https://toolsfordev.com/image-compressor",
    },
    HTMLFormatter: {
        title: 'HTML Formatter: Beautify Your Code with Tools For Dev',
        description: 'Automatically format your HTML code for improved readability and structure, enhancing coding efficiency with our easy-to-use HTML formatter tool.',
        keywords: 'html formatter, html formatter online, online html formatter, free html formatter, html code formatter, html formatter download, html beautify, html css formatter, beautify tools',
        robots: 'index, follow',
        author: 'Tools for Web Team',
        ogImage: logoImage, // Use the imported logo.svg for Open Graph
        canonical : "https://toolsfordev.com/html-formatter",
    },
    HtmlMinifier: {
        title: 'HTML Minifier: Improve Performance with Tools For Dev',
        description: 'Effortlessly minify your HTML code to improve website performance, reduce load times, and enhance the user experience with our reliable HTML minifier tool.',
        keywords: 'html minifier, html minifier online, php html minifier, best html minifier',
        robots: 'index, follow',
        author: 'Tools for Web Team',
        ogImage: logoImage, // Use the imported logo.svg for Open Graph
        canonical : "https://toolsfordev.com/html-minifier",
    },
    CSSFormatter: {
        title: 'CSS Formatter: Enhance Your Styles with Tools For Dev',
        description: 'Automatically format your CSS code for better readability and maintainability. Improve your coding workflow with our intuitive CSS formatter tool.',
        keywords: 'css formatter, css formatter online, online css formatter, css code formatter, best css formatter, free css formatter',
        robots: 'index, follow',
        author: 'Tools for Web Team',
        ogImage: logoImage, // Use the imported logo.svg for Open Graph
        canonical : "https://toolsfordev.com/css-formatter",
    },
    CSSMinifier: {
        title: 'CSS Minifier: Optimize Your Styles with Tools For Dev',
        description: 'Minify your CSS code to significantly reduce file sizes and improve loading speed for your website, enhancing overall performance with ease.',
        keywords: 'css minifier, online css minifier, best css minifier, css minifier online, css minifier tool, free css minifier',
        robots: 'index, follow',
        author: 'Tools for Web Team',
        ogImage: logoImage, // Use the imported logo.svg for Open Graph
        canonical : "https://toolsfordev.com/css-minifier",
    },
    pxToVwConverter: {
        title: 'Px to Vw Converter: Responsive Design Made Easy with Tools For Dev',
        description: 'Convert pixel values to viewport width (vw) units effortlessly, ensuring a responsive design and seamless development experience with our conversion tool.',
        keywords: 'px to vw converter,  px to vw converter online, vw to px, 1 vw to px, vw in px, vw to px, px em rem vw',
        robots: 'index, follow',
        author: 'Tools for Web Team',
        ogImage: logoImage, // Use the imported logo.svg for Open Graph
        canonical : "https://toolsfordev.com/px-to-vw",
    },
    pxToRemConverter: {
        title: 'Px to Rem Converter: Simplify Typography for Responsive Design | Tools For Dev',
        description: 'Effortlessly convert pixel values to rem units for scalable and responsive typography with our reliable Px to Rem Converter tool.',
        keywords: 'px to rem converter, px to rem, rem to px, rem calculator, pixel to rem converter, responsive typography tools, px rem calculator',
        robots: 'index, follow',
        author: 'Tools for Web Team',
        ogImage: logoImage, // Use the imported logo.svg for Open Graph
        canonical: 'https://toolsfordev.com/px-to-rem',
    },    
    javascriptFormatter: {
        title: 'JavaScript Formatter: Clean and Readable Code with WebTools',
        description: 'Format your JavaScript code instantly for readability and maintainability. Enhance your workflow with clean, well-structured code using WebTools.',
        keywords: 'javascript formatter, format js code, beautify javascript, clean js, js code prettifier, online javascript formatter, readable js code, WebTools js formatter',
        robots: 'index, follow',
        author: 'Tools for Web Team',
        ogImage: logoImage, // Replace with the appropriate image variable if needed
        canonical : "https://toolsfordev.com/Javascript-formatter",
    },     
    javascriptMinifier: {
        title: 'JavaScript Minifier: Reduce JS File Size with WebTools',
        description: 'Efficiently minify JavaScript code to improve load times and optimize web performance. Instantly remove whitespace and comments from your JS code.',
        keywords: 'javascript minifier, minify js, compress js, js minify tool, online javascript compressor, js optimization, reduce js size',
        robots: 'index, follow',
        author: 'WebTools team',
        ogImage: logoImage, // Replace with the appropriate image variable if needed
        canonical : "https://toolsfordev.com/javascript-minifier",
    },
    WebpConverter: {
        title: 'JPEG/PNG to WEBP Converter',
        description: 'Convert your JPEG and PNG images to the modern, efficient WebP format to reduce file sizes and improve web performance without compromising quality.',
        keywords: 'jpeg to webp, png to webp, convert jpeg to webp, convert png to webp, image converter, online webp converter, compress jpeg, compress png, webp image converter, image optimization tool',
        author: 'WebTools team',
        robots: 'index, follow',
        ogImage: logoImage, // Replace with the appropriate image variable if needed
        canonical: "https://toolsfordev.com/webp-converter",
    },    
    termsofservice : {
        title: 'Terms Of Service',
        description: '',
        keywords: '',
        author: 'WebTools team',
        robots: 'noindex, nofollow',
        ogImage: logoImage, // Replace with the appropriate image variable if needed
        canonical : "https://toolsfordev.com/tools",
    },
    privacyPolicy : {
        title: 'privacy Policy',
        description: '',
        keywords: '',
        author: 'WebTools team',
        robots: 'noindex, nofollow',
        ogImage: logoImage, // Replace with the appropriate image variable if needed
        canonical : "https://toolsfordev.com/tools",
    }
};