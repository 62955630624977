// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client'; // For React 18+
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import './index.css'; // Import your CSS file
import Header from './Components/Common/Header';
import Footer from './Components/Common/Footer';

// Main rendering
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Router>
      <Header/>
        <App />
        <Footer/>
    </Router>
);
