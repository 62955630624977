"use client"; // Ensure this is added if needed in your project

import React, { useState } from 'react';
import { Loader } from '../Common/Loader';

const HtmlMinifierTool = () => {
  const [inputHtml, setInputHtml] = useState('');
  const [minifiedHtml, setMinifiedHtml] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  // Function to manually minify HTML
  const minifyHtml = (html) => {
    return html
      .replace(/\n/g, '') // Remove line breaks
      .replace(/\s+/g, ' ') // Collapse multiple spaces into one
      .replace(/>\s+</g, '><'); // Remove spaces between tags
  };

  // Handle input change
  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputHtml(value);

    if (value.trim()) {
      setIsLoading(true);
      setError('');

      try {
        const minified = minifyHtml(value);
        setMinifiedHtml(minified);
      } catch (err) {
        console.error(err); // Log error for debugging
        setError('Error minifying HTML');
      } finally {
        setIsLoading(false);
      }
    } else {
      // Clear output if input is empty
      setMinifiedHtml('');
    }
  };

  const handleClear = () => {
    setInputHtml('');
    setMinifiedHtml('');
    setError('');
  };

  const handleCopy = () => {
    if (minifiedHtml) {
      navigator.clipboard.writeText(minifiedHtml)
        .then(() => {
          setShowAlert(true);
          setTimeout(() => setShowAlert(false), 2000); // Hide after 2 seconds
        })
        .catch((err) => {
          console.error('Failed to copy text:', err);
        });
    }
  };

  return (
    <section className='hero' id='hero'>
    <div className='container'>
      <div className="p-4 my-10">
        <div className='row'>
          <div className='lg:w-6/12 w-full px-5'>
            <h1 className="font-bold mb-10">HTML Minifier</h1>
            <textarea
              rows={10}
              className="w-full p-2 border border-gray-300 rounded-md mb-4 focus:outline-none focus:ring-2 focus:ring-offset-gray-800 min-h-[500px]"
              value={inputHtml}
              onChange={handleInputChange}
              placeholder="Enter HTML code"
            />
            {error && <p className="text-[red] mt-2">{error}</p>}
            <button onClick={handleClear} className="primary-btn mt-5 btn-large">
              Clear
            </button>
          </div>

          <div className='lg:w-6/12 w-full px-5'>
            <h3 className="lg:mt-0 mt-10 mb-10 h2">Minified HTML</h3>
            {isLoading ? <Loader /> : (
              <>
                <textarea
                  rows={10}
                  className="w-full p-2 border border-gray-300 rounded-md mb-4 focus:outline-none focus:ring-2 focus:ring-offset-gray-800 min-h-[500px]"
                  value={minifiedHtml}
                  readOnly
                  placeholder="Minified HTML will appear here..."
                />
                <button onClick={handleCopy} className="primary-btn mt-5 btn-large">
                  Copy
                </button>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Copy Alert */}
      {showAlert && (
        <div className="fixed bottom-4 right-4 bg-gray-900 text-white px-4 py-2 rounded shadow-lg transition-opacity duration-500 ease-out opacity-100 animate-fade-out">
          Minified HTML copied to clipboard!
        </div>
      )}
    </div>
    </section>
  );
};

export default HtmlMinifierTool;
