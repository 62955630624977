"use client";
  
import { useEffect } from "react"; 
import WhyChoose from "../Components/ImageCompressor/WhyChoose";
import setMeta from "../setMeta";
import HomeFeaturesSection from "../Components/Home/features";
import WebpConverter from "../Components/ImageCompressor/webp-converter";
 

export default function WebpConveter() {   
  useEffect(() => {
    setMeta('WebpConverter'); // Set meta for this specific page
}, []);
  return (
    <>  
     <WebpConverter/> 
      <WhyChoose/>
      {/* <GetStarted/> */}
     {/* <GetStarted/> */}
<section id="webp-converter" className="py-20 bg-gray-100 [&_a]:text-primary hover:[&_a]:text-secondary">
  <div className="container mx-auto">
    <h2 className="mb-6">Convert Images to WebP</h2>
    
    <p className="mb-4">
      Convert your JPEG and PNG images to the efficient WebP format to reduce file sizes without sacrificing quality. Our WebP Converter tool allows you to upload and convert images in a few easy steps, ensuring faster loading times for your website.
    </p>

    <h3 className="text-xl font-semibold mt-6 mb-4">How It Works</h3>
    <ol className="list-decimal list-inside mb-4">
      <li><strong>Upload Your Images :</strong> Drag and drop your JPEG or PNG images into the designated area, or click to browse your files.</li>
      <li><strong>Conversion to WebP</strong>: Our tool will convert your images into WebP format, offering a significant reduction in file size while maintaining high quality.</li>
      <li><strong>Download Your Converted Images: </strong>Once the conversion is complete, you can download your optimized WebP images. Download up to 10 images at once, either individually or all together in a ZIP file.</li>
    </ol>

    <h3 className="text-xl font-semibold mt-6 mb-4">Key Features</h3>
    <ul className="list-disc list-inside mb-4">
      <li><strong>Multiple Formats Supported</strong>: Convert JPEG and PNG images to WebP to reduce file sizes and improve load times.</li>
      <li><strong>User-Friendly Interface</strong>: Our simple, intuitive design makes converting images quick and hassle-free.</li>
      <li><strong>Fast Conversion</strong>: Our advanced algorithms ensure fast image conversion with no loss of quality.</li>
      <li><strong>Preview Before Download</strong>: Check the quality of your converted images before saving them to your device.</li>
      <li><strong>Privacy Guaranteed</strong>: Your images are processed securely and deleted from our servers after conversion.</li>
    </ul>

    <h3 className="text-xl font-semibold mt-6 mb-4">Benefits of Converting to WebP</h3>
    <ul className="list-disc list-inside mb-4">
      <li><strong>Faster Load Times</strong>: WebP images load faster, improving the overall performance of your website.</li>
      <li><strong>Better User Experience</strong>: Enhance user satisfaction with faster loading images and smooth browsing.</li>
      <li><strong>SEO Advantages</strong>: Optimized WebP images contribute to better SEO rankings, as faster page speeds are a key ranking factor.</li>
      <li><strong>Reduced Bandwidth Costs</strong>: Smaller WebP image sizes save on bandwidth, especially important for high-traffic websites.</li>
    </ul>

    <h3 className="text-xl font-semibold mt-6 mb-4">Get Started</h3>
    <p>
      Ready to optimize your images and improve your website’s performance? <a title="Try our WebP Converter" href="#hero">Try our WebP Converter</a> today and experience the difference in speed and efficiency!
    </p>
  </div>
</section>


<HomeFeaturesSection/>  

     </>
  );
}
