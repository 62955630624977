import React from "react";
import BannerImage from '../../Images/home-hero.jpg'
export default function HomeHeroSection() {
  return (
    <>
 

      <div className="relative bg-cover bg-center" id="home"> 
      <div className="w-full pb-20 pt-10 relative">
        <img src={BannerImage} alt="Tools For Dev" title="Tools For Dev" />
        </div>
    <div className="absolute inset-0 bg-gradient-to-br from-black/60 to-black/30"></div>
    <div className="container mx-auto px-4 relative z-10">
        <div className="text-center">
            <h1 className="mb-2">
                Welcome to <span className="text-primary">Tools For Dev</span>
            </h1>
            <p className="mt-2 text-lg lg:text-xl mb-8">
                Your one-stop solution for essential web development tools to streamline workflows.
            </p> 
        </div>

        <div className="mt-4 grid grid-cols-1 lg:grid-cols-3 gap-8 z-10">
            <div className="p-6 bg-white rounded-lg shadow-lg text-gray-900">
                <h2 className="h5 font-bold mb-2">The Best Prices for Web Development Tools</h2>
                <p className="text-gray-600">
                    Access free tools for developers, providing valuable resources like image compressors, HTML formatters, and CSS minifiers to enhance productivity without cost.
                </p>
            </div>
            <div className="p-6 bg-white rounded-lg shadow-lg text-gray-900">
                <h2 className="h5 font-bold mb-2">Unmatched Performance</h2>
                <p className="text-gray-600">
                    Our developer tools offer fast and efficient performance to boost productivity, including top-performing image compressors and HTML/CSS formatters.
                </p>
            </div>
            <div className="p-6 bg-white rounded-lg shadow-lg text-gray-900">
                <h2 className="h5 font-bold mb-2">Trusted by Thousands of Developers</h2>
                <p className="text-gray-600">
                    Join a community of developers who rely on our tools, from CSS minifiers to HTML formatters, for all their web development needs.
                </p>
            </div>
        </div>
    </div>
</div>

      </>
  );
}
