import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import Star from '../../Images/star.png';

const FAQ = () => {
  const faqs = [
    {
      question: "What is the Tools For Dev project about?",
      answer: "Our Tools For Dev project offers a range of user-friendly tools designed to simplify web development and design processes. From HTML formatting to image compression, we provide solutions that enhance your efficiency and streamline your workflow."
    },
    {
      question: "Are the tools free to use?",
      answer: "Yes, all our tools are completely free to use. We believe in making web development resources accessible to everyone, regardless of their experience level."
    },
    {
      question: "Do I need to create an account?",
      answer: "No, there’s no need to create an account to use our tools. You can access any tool directly from our website without any registration."
    },
    {
      question: "Can I suggest new features or tools?",
      answer: "Absolutely! We value your feedback and suggestions. Please use the contact form in the footer to share your ideas and help us improve."
    },
    {
      question: "Are my files and data secure?",
      answer: "Yes, we prioritize your privacy and security. All files are processed locally on your device and are not stored on our servers, ensuring your data remains confidential."
    }
];


  const [openIndex, setOpenIndex] = useState(null);

  // Remove TypeScript type for index
  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className="bg-gradient-to-br from-blue-50 to-blue-100 py-16 px-4 sm:px-6 lg:px-8"> 
      <div className="w-container text-center mb-16">
      <div className='text-center w-full'>
              <img src={Star} width={24} height={24} className='mx-auto' alt='Frequently Asked Questions' />
          </div>
        <h2 className="font-bold text-gray-700 mb-6">
          Frequently Asked Questions
        </h2> 
      </div>

      <div className="max-w-4xl mx-auto space-y-4">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className={`bg-white shadow-md rounded-lg p-5 transition-all duration-300 cursor-pointer ${openIndex === index ? 'shadow-lg' : ''}`}
            onClick={() => toggleFAQ(index)}
          >
            <div className="flex justify-between items-center">
              <h3 className="text-lg font-semibold text-gray-800 m-0">{faq.question}</h3>
              <div className="text-secondary">
                {openIndex === index ? <FaChevronUp /> : <FaChevronDown />}
              </div>
            </div>
            {openIndex === index && (
              <p className="mt-4 text-gray-600">
                {faq.answer}
              </p>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default FAQ;
