import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom'; 
import Home from './Pages/Home';
import ImageCompressor from './Pages/ImageCompressor';
import HTMLFormatter from './Pages/html-formatter';
import HtmlMinifier from './Pages/html-minifier';
import CSSFormatter from './Pages/Css-formatter';
import CSSMinifier from './Pages/css-minifier'; 
import PrivacyPolicy from './Pages/privacyPolicy';
import PrivacyPolicyAndTerms from './Pages/terms-of-service';
import PxToVw from './Pages/px-to-vw';
import PxTorem from './Pages/px-to-rem';
import Tools from './Pages/Tools';
import JavascriptFormatter from './Pages/javascript-formatter';
import JavascriptMinifier from './Pages/javascript-minifier';
import WebpConveter from './Pages/webp-converter';

const App = () => {  

  // Custom hook for scrolling to top on route change
  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };

  return ( 
    <>
      <ScrollToTop /> {/* Place it here, outside <Routes> */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/image-compressor" element={<ImageCompressor />} />
        <Route path="/html-formatter" element={<HTMLFormatter />} />
        <Route path="/html-minifier" element={<HtmlMinifier />} />
        <Route path="/css-formatter" element={<CSSFormatter />} />
        <Route path="/css-minifier" element={<CSSMinifier />} /> 
        <Route path="/javascript-formatter" element={<JavascriptFormatter />} />
        <Route path="/javascript-minifier" element={<JavascriptMinifier />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} /> 
        <Route path="/terms-of-service" element={<PrivacyPolicyAndTerms />} /> 
        <Route path="/px-to-vw" element={<PxToVw/>} /> 
        <Route path="/px-to-rem" element={<PxTorem/>} /> 
        <Route path="/tools" element={<Tools/>} /> 
        <Route path="/webp-conveter" element={<WebpConveter/>} /> 
      </Routes> 
    </>
  );
};

export default App;
