// src/components/PrivacyPolicy.jsx
import React, { useEffect } from 'react';
import setMeta from '../setMeta';

const PrivacyPolicy = () => {
    useEffect(() => {
        setMeta('privacyPolicy'); // Set meta for this specific page
      }, []);
    return (
        <div className="min-h-screen flex flex-col py-16">
            <div className="bg-green-600 text-white py-4">
                <h1 className="text-center">Privacy Policy</h1>
            </div>

            <main className="flex-1 container mx-auto p-4">
                <h2 className="text-3xl font-bold mb-4">Privacy Policy for Tools For Dev</h2>

                <section className="mb-6">
                    <h3 className="text-xl font-semibold">Introduction</h3>
                    <p className="mt-2">
                        At Tools For Dev, we prioritize your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data when you use our tools.
                    </p>
                </section>

                <section className="mb-6">
                    <h3 className="text-xl font-semibold">Information We Collect</h3>
                    <p className="mt-2">
                        We may collect personal information such as your name and email address when you use our tools, including the Image Compressor, HTML Formatter, CSS Formatter, and other tools. This data helps us improve our services and provide better user experiences.
                    </p>
                </section>

                <section className="mb-6">
                    <h3 className="text-xl font-semibold">How We Use Your Information</h3>
                    <p className="mt-2">
                        The information we collect may be used to:
                        <ul className="list-disc ml-6 mt-2">
                            <li>Enhance and personalize user experience.</li>
                            <li>Improve our website and tools.</li>
                            <li>Notify you of updates or new features related to our tools.</li>
                        </ul>
                    </p>
                </section>

                <section className="mb-6">
                    <h3 className="text-xl font-semibold">Cookies</h3>
                    <p className="mt-2">
                        Our website uses cookies to improve user experience. You can choose to accept or decline cookies through your browser settings.
                    </p>
                </section>

                <section className="mb-6">
                    <h3 className="text-xl font-semibold">Data Security</h3>
                    <p className="mt-2">
                        We take reasonable measures to protect your information. However, no method of transmission over the Internet or method of electronic storage is completely secure.
                    </p>
                </section>

                <section className="mb-6">
                    <h3 className="text-xl font-semibold">Changes to This Privacy Policy</h3>
                    <p className="mt-2">
                        We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new policy on this page.
                    </p>
                </section>

                <section className="mb-6">
                    <h3 className="text-xl font-semibold">Contact Us</h3>
                    <p className="mt-2">
                        If you have any questions about this Privacy Policy or our tools, please contact us at <a href="mailto:desaiknl@gmail.com" className="text-green-600">desaiknl@gmail.com</a>.
                    </p>
                </section>
            </main>
        </div>
    );
};

export default PrivacyPolicy;
