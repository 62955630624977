import React, { useState, useEffect } from 'react';
import { Loader } from '../Common/Loader'; // Assuming you have a Loader component for showing loading state

const formatCSS = (css) => {
  return css
    .replace(/;\s*/g, ';\n    ') // Add newline and indentation after each semicolon
    .replace(/\{\s*/g, ' {\n    ') // Add newline and indentation after each opening brace
    .replace(/\}\s*/g, '\n}\n') // Add a newline before each closing brace
    .replace(/\n\s*\n/g, '\n') // Remove extra empty lines
    .trim(); // Trim whitespace
};

const CSSFormatterTool = () => {
  const [inputCss, setInputCss] = useState('');
  const [formattedCss, setFormattedCss] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (inputCss) {
      setIsLoading(true);
      setError('');

      try {
        const formatted = formatCSS(inputCss);
        setFormattedCss(formatted);
      } catch (err) {
        console.error(err);
        setError('Error formatting CSS. Please check your input.');
        setFormattedCss('');
      } finally {
        setIsLoading(false);
      }
    } else {
      setFormattedCss('');
    }
  }, [inputCss]);

  const handleClear = () => {
    setInputCss('');
    setFormattedCss('');
    setError('');
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(formattedCss)
      .then(() => {
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 2000);
      })
      .catch(err => console.error('Error copying text: ', err));
  };

  return (
    <section className="w-full" id="hero">
      <div className="container">
        <div className="p-4 my-10">
          <div className="row">
            <div className="lg:w-6/12 w-full px-5">
              <h1 className="font-bold mb-10">CSS Formatter</h1>
              <textarea
                rows={10}
                className="w-full p-2 border border-gray-300 rounded-md mb-4 focus:outline-none focus:ring-2 focus:ring-offset-gray-800 min-h-[500px]"
                value={inputCss}
                onChange={(e) => setInputCss(e.target.value)}
                placeholder="Enter CSS code"
              />
              {error && <p className="text-[red] mt-2">{error}</p>}
              <button onClick={handleClear} className="primary-btn mt-5 btn-large">
                Clear
              </button>
            </div>

            <div className="lg:w-6/12 w-full px-5">
              <h3 className="lg:mt-0 mt-10 mb-10 h2">Formatted CSS</h3>
              {isLoading ? (
                <Loader />
              ) : (
                <>
                  <textarea
                    rows={10}
                    className="w-full p-2 border border-gray-300 rounded-md mb-4 focus:outline-none focus:ring-2 focus:ring-offset-gray-800 min-h-[500px]"
                    value={formattedCss}
                    readOnly
                    placeholder="Formatted CSS will appear here..."
                  />
                  <button onClick={handleCopy} className="primary-btn mt-5 btn-large">
                    Copy
                  </button>
                </>
              )}
            </div>
          </div>
        </div>

        {showAlert && (
          <div className="fixed bottom-4 right-4 bg-gray-900 text-white px-4 py-2 rounded shadow-lg transition-opacity duration-500 ease-out opacity-100 animate-fade-out">
            Formatted CSS copied to clipboard!
          </div>
        )}
      </div>
    </section>
  );
};

export default CSSFormatterTool;
