// src/Components/Common/Footer.js
import React from 'react';
//import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';  
//import HomeFeaturesSection from '../Home/features';
//import { Loader } from './Loader';

const Footer = () => {
/* 
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        // Here you can implement your own logic or API call to send the email when needed.
        // For now, just simulate a successful submission
        setTimeout(() => {
            setStatus('Your message has been sent!'); // Simulate successful submission
            setName('');
            setEmail('');
            setMessage('');
            setLoading(false);
        }, 1000);
    };

    */
    return (
        <>  
        <footer className="bg-gray-800 py-20 text-center">
            <div className="max-w-7xl mx-auto">
              {/*  <h2 className="text-2xl font-bold mb-6 text-white">Contact Us</h2>
                 <form onSubmit={handleSubmit} className="max-w-md mx-auto mb-8 bg-gray-900 p-6 rounded-lg shadow-md">
                    <label htmlFor="name" className="block mb-1 text-gray-300">Your Name</label>
                    <input
                        id="name"
                        type="text"
                        aria-label="Your Name"
                        placeholder="Enter your name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                        className="w-full p-3 mb-4 bg-gray-800 border border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150"
                    />
                    <label htmlFor="email" className="block mb-1 text-gray-300">Your Email</label>
                    <input
                        id="email"
                        type="email"
                        aria-label="Your Email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        className="w-full p-3 mb-4 bg-gray-800 border border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150"
                    />
                    <label htmlFor="message" className="block mb-1 text-gray-300">Your Message</label>
                    <textarea
                        id="message"
                        aria-label="Your Message"
                        placeholder="Write your message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                        className="w-full p-3 mb-4 bg-gray-800 border border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150"
                        rows={4}
                    />
                    <button
                        type="submit"
                        className="bg-blue-600 hover:bg-blue-700 transition duration-200 p-3 rounded w-full text-white font-semibold"
                        disabled={loading}
                    >
                        {loading ? <Loader /> : 'Send Message'}
                    </button>
                </form> 
                {status && (
                    <p className={`mt-4 ${status.includes('sent') ? 'text-green-500' : 'text-red-500'}`}>
                        {status}
                    </p>
                )}*/}

                {/* Helpful Links */}
                <div className="mt-8">
                    <h3 className="text-lg font-bold text-white mb-4">Helpful Links</h3>
                    <ul className="text-gray-400 space-y-2">
                    <li>
                            <a href="jaavascript:void(0)" className="hover:underline">mail us on desaiknl@gmail.com</a>
                        </li>
                        <li>
                            <a href="/privacy-policy" className="hover:underline">Privacy Policy</a>
                        </li>
                        <li>
                            <a href="/terms-of-service" className="hover:underline">Terms of Service</a>
                        </li>
                      
                    </ul>
                </div>

                {/* Social Media Links */}
                {/* <div className="mt-6 flex justify-center space-x-4">
                    <a href="#" className="text-white hover:text-gray-400" aria-label="Facebook">
                        <FaFacebook size={24} />
                    </a>
                    <a href="#" className="text-white hover:text-gray-400" aria-label="Twitter">
                        <FaTwitter size={24} />
                    </a>
                    <a href="#" className="text-white hover:text-gray-400" aria-label="Instagram">
                        <FaInstagram size={24} />
                    </a>
                    <a href="#" className="text-white hover:text-gray-400" aria-label="LinkedIn">
                        <FaLinkedin size={24} />
                    </a>
                </div> */}

                <p className="mt-6 text-gray-400">&copy; {new Date().getFullYear()} Tools For Dev. All rights reserved.</p>
            </div>
        </footer>
        </>
    );
};

export default Footer;
