"use client";
  
import { useEffect } from "react";
//import GetStarted from "../Components/ImageCompressor/GetStarted";
import ImageCompressorTool from "../Components/ImageCompressor/ImageCompressorTool";
import WhyChoose from "../Components/ImageCompressor/WhyChoose";
import setMeta from "../setMeta";
import HomeFeaturesSection from "../Components/Home/features";
 

export default function ImageCompressor() {   
  useEffect(() => {
    setMeta('ImageCompressor'); // Set meta for this specific page
}, []);
  return (
    <>  
     <ImageCompressorTool/> 
      <WhyChoose/>
      {/* <GetStarted/> */}
      <section id="image-compressor" className="py-20 bg-gray-100 [&_a]:text-primary hover:[&_a]:text-secondary">
  <div className="container mx-auto">
    <h2 className="mb-6">Reduce image size</h2>
    
    <p className="mb-4">
      Reduce the size of your images without sacrificing quality. Our Image Compressor tool allows you to upload images in various formats and compress them efficiently, ensuring your web pages load quickly and look great.
    </p>

    <h3 className="text-xl font-semibold mt-6 mb-4">How It Works</h3>
    <ol className="list-decimal list-inside mb-4">
      <li><strong>Upload Your Images :</strong>: Drag and drop your images into the designated area, or click to browse your files.</li>
      <li><strong>Type Of Compression</strong>: We currently offer <strong>lossy compression,</strong> which reduces file size with a slight reduction in quality, providing you with a good balance between file size and visual clarity.</li>
      <li><strong>Download Your Compressed Images: </strong>: Once the compression is complete, you can download your optimized images. You can download up to 10 images at a time, either individually or all at once in a convenient ZIP file.</li>
    </ol>

    <h3 className="text-xl font-semibold mt-6 mb-4">Key Features</h3>
    <ul className="list-disc list-inside mb-4">
      <li><strong>Multiple Formats Supported</strong>: Upload and compress images in JPEG and PNG formats to meet your needs.</li>
      <li><strong>User-Friendly Interface</strong>: Our intuitive design makes it easy for anyone to compress images quickly and effectively.</li>
      <li><strong>Fast Processing</strong>: Our advanced algorithms ensure speedy compression without compromising quality.</li>
      <li><strong>Preview Before Download</strong>: Check the quality of your compressed images before saving them to your device.</li>
      <li><strong>Privacy Guaranteed</strong>: Your images are processed securely and deleted from our servers after compression.</li>
    </ul>

    <h3 className="text-xl font-semibold mt-6 mb-4">Benefits of Image Compression</h3>
    <ul className="list-disc list-inside mb-4">
      <li><strong>Faster Load Times</strong>: Compressed images load quicker, improving the overall performance of your website.</li>
      <li><strong>Better User Experience</strong>: Enhance user satisfaction by reducing waiting times and providing smooth browsing experiences.</li>
      <li><strong>SEO Advantages</strong>: Optimized images contribute to better SEO rankings, as page speed is a crucial factor in search engine algorithms.</li>
      <li><strong>Reduced Bandwidth Costs</strong>: Smaller image sizes save on bandwidth, especially important for websites with high traffic.</li>
    </ul>

    <h3 className="text-xl font-semibold mt-6 mb-4">Get Started</h3>
    <p>
      Ready to improve your website's performance? <a title="Try our Image Compressor" href="#hero">Try our Image Compressor</a> today and see the difference in speed and quality!
    </p>
  </div>
</section>

<HomeFeaturesSection/>  

     </>
  );
}
