"use client";
import { useEffect } from "react"; 
import setMeta from "../setMeta";
import HomeFeaturesSection from "../Components/Home/features"; 
import JavascriptMinifierTool from "../Components/javascript-minifier/javascript-minifier";
// Marking this component as a Client Component
 const JavascriptMinifier = () => { 
  useEffect(() => {
    setMeta('javascriptMinifier'); // Set meta for this specific page
}, []);
  return (
     <>
    <JavascriptMinifierTool/>
    <section id="html-minifier" className="py-20 bg-gray-100 [&_a]:text-primary hover:[&_a]:text-secondary">
    <div className="container mx-auto">
    <h2 className="mb-6">JavaScript Minifier</h2>
    
    <p className="mb-4">
      Reduce file size and optimize performance with our JavaScript Minifier tool. This tool compresses your JavaScript code by removing unnecessary whitespace, comments, and optimizing syntax. Ideal for developers looking to speed up website load times.
    </p>

    <h3 className="text-xl font-semibold mt-6 mb-4">How It Works</h3>
    <ol className="list-decimal list-inside mb-4">
      <li><strong>Enter Your JavaScript Code</strong>: Paste your JavaScript code into the input area.</li>
      <li><strong>Initiate Minification</strong>: The tool automatically compresses your code, reducing its size while maintaining functionality.</li>
      <li><strong>Copy the Minified Code</strong>: View and copy the compacted JavaScript code, ready for deployment.</li>
    </ol>

    <h3 className="text-xl font-semibold mt-6 mb-4">Key Features</h3>
    <ul className="list-disc list-inside mb-4">
      <li><strong>Automatic Minification</strong>: Instantly see a smaller version of your JavaScript code as you input it.</li>
      <li><strong>Size Reduction</strong>: Significantly decrease file size by removing comments, whitespace, and redundant code.</li>
      <li><strong>Optimized for Web Performance</strong>: Minified JavaScript enhances load times and reduces bandwidth usage.</li>
      <li><strong>Download Option</strong>: Save your minified JavaScript code directly for use in production.</li>
      <li><strong>Responsive Design</strong>: Access the tool seamlessly from both desktop and mobile devices.</li>
    </ul>

    <h3 className="text-xl font-semibold mt-6 mb-4">Benefits of Using JavaScript Minifier</h3>
    <ul className="list-disc list-inside mb-4">
      <li><strong>Improved Load Speed</strong>: Minified JavaScript loads faster, providing a better user experience.</li>
      <li><strong>Reduced Bandwidth</strong>: Smaller file sizes mean less data usage, ideal for mobile users and large-scale deployments.</li>
      <li><strong>SEO Boost</strong>: Faster load times can improve search engine rankings and site visibility.</li>
      <li><strong>Code Obfuscation</strong>: Minified code is harder to read, adding a layer of protection for proprietary logic.</li>
    </ul>

    <h3 className="text-xl font-semibold mt-6 mb-4">Get Started</h3>
    <p>
      Ready to clean up your HTML code? <a title="Try our javascript Minifier" href="#hero">Try our HTML Formatter</a> today and experience the difference in your coding workflow!
    </p>
  </div>
</section>
<HomeFeaturesSection/>  
     </>
  );
};

export default JavascriptMinifier;