// src/Tools.jsx
import React, { useEffect } from 'react';
import setMeta from '../setMeta';
import HomeFeaturesSection from '../Components/Home/features';

const Tools = () => {

    useEffect(() => {
        setMeta('tools'); // Set meta for this specific page
      }, []);
  

    return (
        <HomeFeaturesSection/>  
    );
};

export default Tools;