import Star from '../../Images/star.png';

const KeyFeatures = () => {
  const features = [
    {
      title: "Image Compressor",
      description: "Effortlessly reduce image sizes without losing quality, optimizing them for web usage and enhancing site performance.",
      icon: (
        <svg className="w-12 h-12" fill="none" stroke="#9333ea" strokeWidth="2" viewBox="0 0 24 24">
          <path d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m0 0l-7 7-7-7m14 0V5a1 1 0 00-1-1h-3"></path>
        </svg>
      ),
      color: "text-blue-500"
    },
    {
      title: "HTML Formatter & Minifier",
      description: "Format and minify your HTML code to enhance readability and optimize performance for faster loading times.",
      icon: (
        <svg className="w-12 h-12" fill="none" stroke="#9333ea" strokeWidth="2" viewBox="0 0 24 24">
          <path d="M5 3v4a2 2 0 002 2h10a2 2 0 002-2V3m-3 8l-3 3m0 0l-3-3m3 3V7"></path>
        </svg>
      ),
      color: "text-green-500"
    },
    {
      title: "CSS Formatter & Minifier",
      description: "Automatically format and minify CSS code, enhancing code readability while improving page load speed.",
      icon: (
        <svg className="w-12 h-12" fill="none" stroke="#9333ea" strokeWidth="2" viewBox="0 0 24 24">
          <path d="M12 8c.637 0 1.287.125 1.876.362m1.804-1.04A7.99 7.99 0 0112 4c-1.448 0-2.794.385-3.961 1.055m7.57 11.018A8.002 8.002 0 014 12a8.001 8.001 0 015.076-7.438m9.255 2.032A7.99 7.99 0 0120 12c0 1.448-.385 2.794-1.055 3.961"></path>
        </svg>
      ),
      color: "text-purple-500"
    },
    {
      title: "Px to Vw Converter",
      description: "Convert pixel values to viewport width units easily, ensuring responsive design that adapts to all devices.",
      icon: (
        <svg className="w-12 h-12" fill="none" stroke="#9333ea" strokeWidth="2" viewBox="0 0 24 24">
          <path d="M19 12H5m7 7l-7-7 7-7"></path>
        </svg>
      ),
      color: "text-red-500"
    },
    {
      title: "Rem to Px Converter",
      description: "Easily convert rem values to pixels, helping you standardize units across your web development projects.",
      icon: (
        <svg className="w-12 h-12" fill="none" stroke="#9333ea" strokeWidth="2" viewBox="0 0 24 24">
          <path d="M12 4v16m8-8H4"></path>
        </svg>
      ),
      color: "text-yellow-500"
    },
    {
      title: "Upcoming Tools",
      description: "Stay tuned for more exciting tools like Font Converter, JSON Formatter, and more to enhance your development experience!",
      icon: (
        <svg className="w-12 h-12" fill="none" stroke="#9333ea" strokeWidth="2" viewBox="0 0 24 24">
          <path d="M12 8c.637 0 1.287.125 1.876.362m1.804-1.04A7.99 7.99 0 0112 4c-1.448 0-2.794.385-3.961 1.055m7.57 11.018A8.002 8.002 0 014 12a8.001 8.001 0 015.076-7.438m9.255 2.032A7.99 7.99 0 0120 12c0 1.448-.385 2.794-1.055 3.961"></path>
        </svg>
      ),
      color: "text-indigo-500"
    }
];


  return (
    <section className="bg-gray-100 py-12 px-6 md:px-12 lg:px-24 my-20">
      <div className="max-w-screen-lg mx-auto text-center"> 
        <div className="w-full text-center mb-16">
        <div className='text-center w-full'>
              <img src={Star} width={24} height={24} className='mx-auto' alt='Key Features' />
          </div>
          <h2 className="font-bold text-gray-700 mb-6">
          Key Features
          </h2> 
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <div
              key={index}
              className="bg-white shadow-lg p-6 rounded-lg hover:shadow-xl transition-shadow duration-300"
            >
              <div className={`flex justify-center items-center mb-4 ${feature.color}`}>
                {feature.icon}
              </div>
              <h3 className="h6 font-semibold text-gray-700 mb-2">{feature.title}</h3>
              <p className="text-gray-500">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default KeyFeatures;