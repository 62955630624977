// src/setMeta.js
import { metaConfig } from './metaConfig';

function setMeta(slug) {
    const meta = metaConfig[slug] || metaConfig.home; // Default to 'home' if slug doesn't match

    // Set the document title
    document.title = meta.title;

    // Function to update meta tag or create it if not exists
    const updateMetaTag = (name, content) => {
        let metaTag = document.querySelector(`meta[name="${name}"]`) || document.createElement('meta');
        metaTag.name = name;
        metaTag.content = content;
        document.head.appendChild(metaTag);
    };

    // Set meta description
    updateMetaTag('description', meta.description);
    updateMetaTag('keywords', meta.keywords);
    updateMetaTag('robots', meta.robots);
    updateMetaTag('author', meta.author);
    updateMetaTag('canonical', meta.canonical);

    // Set Open Graph title and description
    const updateOgTag = (property, content) => {
        let ogTag = document.querySelector(`meta[property="${property}"]`) || document.createElement('meta');
        ogTag.setAttribute('property', property);
        ogTag.content = content;
        document.head.appendChild(ogTag);
    };
    updateOgTag('title', meta.title);
    updateOgTag('og:title', meta.title);
    updateOgTag('description', meta.description);
    updateOgTag('og:description', meta.description);
    updateOgTag('canonical', meta.canonical);
    updateOgTag('og:image', meta.ogImage); 
}

export default setMeta;
