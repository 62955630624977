import React from 'react';  
import { Link, useLocation } from 'react-router-dom';

function Header() { 
  const { pathname } = useLocation();

  return (
    <header>
      <nav className="z-10 w-full">
        <div className="container">
          <div className="flex flex-wrap items-center justify-between py-2 gap-6 md:py-4 md:gap-0 relative">
            <input
              aria-hidden="true"
              type="checkbox"
              name="toggle-nav"
              id="toggle-nav"
              className="hidden peer"
            />
            <div className="relative z-20 w-full flex justify-between lg:w-max md:px-0">
              <a
                href="/"
                aria-label="logo"
                className="flex space-x-2 items-center"
              >
                <div aria-hidden="true" className="flex space-x-1">
                  <div className="h-4 w-4 rounded-full bg-gray-900"></div>
                  <div className="h-6 w-2 bg-primary"></div>
                </div>
                <span className="text-2xl font-bold text-gray-900">
                  Tools For Dev
                </span>
              </a>
              <div className="relative flex items-center lg:hidden max-h-10">
                <label
                  role="button"
                  htmlFor="toggle-nav"
                  aria-label="hamburger"
                  id="hamburger"
                  className="relative p-6"
                >
                  <div className="m-auto h-0.5 w-5 rounded bg-primary transition duration-300"></div>
                  <div className="m-auto mt-2 h-0.5 w-5 rounded bg-primary transition duration-300"></div>
                </label>
              </div>
            </div>
            <div
              aria-hidden="true"
              className="fixed z-10 inset-0 h-screen w-screen backdrop-blur-2xl origin-bottom scale-y-0 transition duration-500 peer-checked:origin-top peer-checked:scale-y-100 lg:hidden"
            ></div>
            <div
              className="flex-col z-20 border-primary flex-wrap gap-6 p-8 rounded-3xl border shadow-2xl shadow-gray-600/10 justify-end w-full invisible opacity-0 translate-y-1 absolute top-full left-0 transition-all duration-300 scale-95 origin-top bg-[#fff]
                lg:relative lg:scale-100 lg:peer-checked:translate-y-0 lg:translate-y-0 lg:flex lg:flex-row lg:items-center lg:gap-0 lg:p-0 lg:bg-transparent lg:w-9/12 lg:visible lg:opacity-100 lg:border-none peer-checked:scale-100 peer-checked:opacity-100 peer-checked:visible lg:shadow-none"
            >
              <div className="text-gray-600 lg:pr-4 lg:w-auto w-full lg:pt-0">
                <ul className="tracking-wide font-bold text-sm flex-col flex lg:flex-row gap-3 lg:gap-0">
                  {[
                    {
                      title: 'Image Tools',
                      links: [{ href: '/image-compressor', label: 'Image Compressor' }],
                    },
                    {
                      title: 'HTML Tools',
                      links: [
                        { href: '/html-formatter', label: 'HTML Formatter' },
                        { href: '/html-minifier', label: 'HTML Minifier' },
                      ],
                    },
                    {
                      title: 'CSS Tools',
                      links: [
                        { href: '/css-formatter', label: 'CSS Formatter' },
                        { href: '/css-minifier', label: 'CSS Minifier' },
                      ],
                    },
                    {
                      title: 'Javascript Tools',
                      links: [
                        { href: '/Javascript-formatter', label: 'Javascript formatter' },
                        { href: '/javascript-minifier', label: 'Javascript Minifier' },
                      ],
                    },
                    {
                      title: 'Conversion Tools',
                      links: [
                        { href: '/px-to-vw', label: 'Px to Vw' },
                        { href: '/px-to-rem', label: 'PX to Rem' },
                      ],
                    }, 
                  ].map((item, index) => {
                    // Check if any link in the current item matches the current pathname
                    const isActive = item.links.some(link => pathname === link.href);
                    return (
                      <li key={index} className={`relative group `}>
                        <span title={item.title} className={`lg:inline-block hidden md:px-4 py-3 transition cursor-pointer group-hover:text-primary ${isActive ? 'text-primary' : ''}`}>
                          {item.title}
                        </span>
                        <ul className="lg:absolute lg:hidden lg:group-hover:block lg:bg-white lg:shadow-lg lg:rounded lg:w-52 w-full">
                          {item.links.map((link, linkIndex) => (
                            <li key={linkIndex}>
                              <Link
                                to={link.href} title={link.label}
                                className={`block px-4 py-3 ${pathname === link.href ? 'text-primary' : ''} hover:text-primary`}
                              >
                                {link.label}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="mt-12 lg:mt-0">
                <a
                  href="#features"
                  className="relative flex h-9 w-full items-center justify-center px-4 before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
                >
                  <span className="relative text-sm font-semibold text-white">
                    Our Services
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
