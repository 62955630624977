"use client";

import { useState } from 'react';
import { useDropzone } from 'react-dropzone'; 
import imageCompression from 'browser-image-compression';
import JSZip from 'jszip'; 
import { ReactComponent as DownloadIcon } from '../../Images/downloadIcon.svg';
import { BiLoader } from 'react-icons/bi';

export default function ImageCompressorTool() {
  const [compressedImages, setCompressedImages] = useState([]);

  const handleCompress = async (files) => {
    for (const file of files) {
      const fileSizeMB = (file.size / (1024 * 1024)).toFixed(2);
      setCompressedImages(prevState => [
        ...prevState,
        {
          name: file.name,
          originalSize: fileSizeMB,
          newSize: null,
          status: 0,
          compressedUrl: null,
          compressionPercent: null,
        }
      ]);

      const isPNG = file.type === 'image/png';

      // Adjust compression settings
      const compressionOptions = isPNG 
        ? { maxSizeKB: 100, useWebWorker: true, initialQuality: 0.08 }
        : { maxSizeKB: 100, useWebWorker: true, initialQuality: 0.55 };

      try {
        const compressedBlob = await imageCompression(file, compressionOptions);
        const compressedUrl = URL.createObjectURL(compressedBlob);
        const newSizeMB = (compressedBlob.size / (1024 * 1024)).toFixed(2);
        const compressionPercent = (((file.size - compressedBlob.size) / file.size) * 100).toFixed(2);

        setCompressedImages(prevState => {
          const newState = [...prevState];
          const index = newState.findIndex(img => img.name === file.name);
          if (index !== -1) {
            newState[index] = {
              ...newState[index],
              newSize: newSizeMB,
              status: 100,
              compressedUrl: compressedUrl,
              compressionPercent: compressionPercent,
            };
          }
          return newState;
        });
      } catch (error) {
        console.error("Compression error:", error);
      }
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: { 'image/jpeg': [], 'image/png': [] },
    maxFiles: 10,
    onDrop: handleCompress
  });

  const handleDownloadAll = async () => {
    const zip = new JSZip();
    const folder = zip.folder("compressed By Tools for web"); 

    for (const image of compressedImages) {
      if (image.compressedUrl) {
        const response = await fetch(image.compressedUrl);
        const blob = await response.blob();
        folder.file(image.name, blob);
      }
    }

    zip.generateAsync({ type: "blob" }).then((content) => {
      const url = URL.createObjectURL(content);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'compressed By Tools for web.zip';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    });
  };

  return (
    <section>
      <div className='container' id='hero'>
        <div className='row'>
          <div className='col w-full'>
            <div className='pb-5 text-center my-16'>
              <h1>Upload images to compress.</h1>
              <div {...getRootProps()} className='p-10 border-2 rounded-2xl border-dashed border-highlight mb-10 flex flex-wrap items-center justify-center cursor-pointer font-semibold text-xl mt-10 min-h-80 text-secondary'>
                <input {...getInputProps()} />
                <p className='w-full'>Drag & drop up to 10 images here, or click to select files</p>
                <p className='w-full'> Or </p>
                <span className='primary-btn btn-large'> Upload Images </span>
              </div>

              <div>
                <div className='image-list-compressed w-full'>
                  {compressedImages.length > 0 && (
                    <h2 className='mb-5 inline-block'>Compressed Images</h2> 
                  )}

                  {compressedImages.map((image, index) => (
                    <div className='image-list-compressed-item mb-5' key={index}> 
                      <div className='row'>
                        <div className='col w-[100px]'>
                          {image.compressedUrl ? (
                            <a href={image.compressedUrl} download={`${image.name}`}>
                              <img
                                width={60}
                                height={60}
                                className='object-cover w-[60px] h-[60px]'
                                src={image.compressedUrl}
                                alt={image.name}
                              />
                            </a>
                          ) : (
                            <BiLoader />
                          )}
                        </div>

                        <div className='col w-[calc(100%_-_200px)]'>
                          <div className='w-full text-left text-base'>
                            <div className='w-full mb-1 inline-block text-gray-800 font-semibold'>
                              {image.name}
                            </div>

                            <div className='w-full text-right mb-1'>
                              <div className='bg-primary p-2 rounded-3xl' style={{ width: `${image.status}%` }}></div>
                            </div>

                            <div className='w-full'>
                              <p>{image.originalSize} MB to {image.newSize ? image.newSize : '-'} MB
                                {image.status === 100 && (
                                  <span className='font-semibold'> (Compressed by {image.compressionPercent}%) </span>
                                )}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className='col w-[100px] flex flex-wrap items-center justify-center'>
                          {image.compressedUrl ? (
                            <a className='[&_svg]:w-6 [&_svg]:h-auto inline-block p-2' href={image.compressedUrl} download={`${image.name}`}>
                              <DownloadIcon />
                            </a>
                          ) : (
                            <span>Processing...</span>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                {compressedImages.length > 0 && (
                  <button onClick={handleDownloadAll} className='primary-btn mt-5 btn-large'>
                    Download All as ZIP
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
