import React from 'react'; 
import { Link } from 'react-router-dom';
import Star from '../../Images/star.png'; 
import IconImageCompressor  from '../../Images/Image-compressor.svg';
import IconHTMLFormatter  from '../../Images/html-formatter.svg';
import IconHTMLMinifier  from '../../Images/html-minifier.svg';
import IconcssFormatter  from '../../Images/css-formatter.svg';
import IconcssMinifier  from '../../Images/css-minifier.svg';
import Iconpxtorem  from '../../Images/px-to-rem-converter.svg';
import Iconpxtovw  from '../../Images/px-to-vw-converter.svg';

// Common for all Pages
export default function HomeFeaturesSection() {
  const features = [
    {
        title: "Image Compressor",
        description: "Reduce image size without sacrificing quality. Our image compressor supports multiple formats, ensuring faster loading times for web development without loss in detail.",
        linkText: "Try Image Compressor",
        link: "/image-compressor",
        icon: IconImageCompressor,
    },
    {
        title: "HTML Formatter",
        description: "Effortlessly clean and format your HTML code. Our HTML formatter organizes your code, enhancing readability and code quality for developers.",
        linkText: "Try HTML Formatter",
        link: "/html-formatter",
        icon: IconHTMLFormatter,
    },
    {
        title: "HTML Minifier",
        description: "Optimize web pages by reducing HTML file size. The HTML minifier removes unnecessary whitespace and comments, improving page load speed.",
        linkText: "Try HTML Minifier",
        link: "/html-minifier",
        icon: IconHTMLMinifier,
    },
    {
        title: "CSS Formatter",
        description: "Improve the readability and consistency of your CSS files. Our CSS formatter organizes your stylesheets, perfect for maintaining clean code.",
        linkText: "Try CSS Formatter",
        link: "/css-formatter",
        icon: IconcssFormatter,
    },
    {
        title: "CSS Minifier",
        description: "Minimize CSS files by removing unnecessary whitespace and comments for better performance and faster page loading.",
        linkText: "Try CSS Minifier",
        link: "/css-minifier",
        icon: IconcssMinifier,
    },
    {
        title: "PX to REM Converter",
        description: "Convert pixel values to rem for responsive design. Our tool simplifies the conversion process, ideal for modern web development.",
        linkText: "Try PX to REM Converter",
        link: "/px-to-rem",
        icon: Iconpxtorem,
    },
    {
        title: "PX to VW Converter",
        description: "Convert pixel values to vw for layouts that adapt to viewport sizes. Ideal for responsive, modern web design practices.",
        linkText: "Try PX to VW Converter",
        link: "/px-to-vw",
        icon: Iconpxtovw,
    }
];



  return (
    <section id="features" className="py-20 bg-gray-50">
    <div className="container mx-auto">
        <div className="w-full text-center mb-16">
            <div className='text-center w-full'>
                <img src={Star} width={24} height={24} className='mx-auto' alt='Key Features of Web Development Tools' />
            </div>
            <h2 className="font-bold text-gray-700 mb-6">
                Our Services for Web Development
            </h2>
            <p className="text-gray-600">
                Explore our essential tools, like image compressors, HTML formatters, and CSS minifiers, designed to streamline your web development process and enhance productivity.
            </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {features.map((feature) => (
                <div
                    key={feature.title}
                    className="bg-white shadow-md rounded-lg p-6 text-center transition-transform transform hover:scale-105"
                >
                    <img src={feature.icon} alt={`${feature.title}`} width={32} height={32} className='mx-auto' />
                    <h3 className="text-lg font-semibold text-gray-700 mt-4 mb-2">
                        {feature.title}
                    </h3>
                    <p className="text-gray-500 mb-4">
                        {feature.description}
                    </p>
                    <Link title={feature.linkText}
                        to={feature.link}
                        className="text-secondary font-medium hover:underline"
                    >
                        {feature.linkText}
                    </Link>
                </div>
            ))}
        </div>
    </div>
</section>

  );
}
