import React, { useEffect } from "react";
import HomeHeroSection from "../Components/Home/heroSection"; 
import AboutUs from "../Components/Home/aboutUs";
import KeyFeatures from "../Components/Home/keyFeaturesSection";
import HomeFeaturesSection from '../Components/Home/features'
import FAQ from "../Components/Home/faq";
import setMeta from "../setMeta";
 
const Home = () => {
   useEffect(() => {
      setMeta('home'); // Set meta for this specific page
  }, []);
     return(
        <> 
        <main className="w-full mx-auto">
          <HomeHeroSection /> 
          <HomeFeaturesSection/>  
          <AboutUs />  
          <KeyFeatures />
          
          <FAQ/> 
        </main>
        </>
     )
  };
  
  export default Home;