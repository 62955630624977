"use client";
import { useEffect } from "react";
import FormatHTMLTool from "../Components/html-formatter/html-formatter";
import setMeta from "../setMeta";
import HomeFeaturesSection from "../Components/Home/features";
// Marking this component as a Client Component
 const HTMLFormatter = () => { 
  useEffect(() => {
    setMeta('HTMLFormatter'); // Set meta for this specific page
}, []);
  return (
     <>
    <FormatHTMLTool/>
    <section id="html-formatter" className="py-20 bg-gray-100 [&_a]:text-primary hover:[&_a]:text-secondary">
  <div className="container mx-auto">
    <h2 className="mb-6">Html formatter online</h2>
    
    <p className="mb-4">
      Clean and format your HTML code effortlessly with our HTML Formatter tool. This tool organizes your code, making it easier to read and maintain. Perfect for developers looking to improve code quality.
    </p>

    <h3 className="text-xl font-semibold mt-6 mb-4">How It Works</h3>
    <ol className="list-decimal list-inside mb-4">
      <li><strong>Enter Your HTML Code</strong>: Paste your HTML code into the input area.</li>
      <li><strong>Choose Formatting Options</strong>: Select from various formatting options such as indentation, line breaks, and tag formatting to customize how your code will be displayed.</li>
      <li><strong>View the Formatted Output</strong>: After formatting, view your neatly organized HTML code and copy it with one click.</li>
    </ol>

    <h3 className="text-xl font-semibold mt-6 mb-4">Key Features</h3>
    <ul className="list-disc list-inside mb-4">
      <li><strong>Real-time Formatting</strong>: See the changes in real-time as you input your HTML code.</li>
      <li><strong>Customizable Settings</strong>: Tailor the formatting to your style with customizable indentation and spacing options.</li>
      <li><strong>Multi-language Support</strong>: Supports various HTML versions and frameworks for better compatibility.</li>
      <li><strong>Download Option</strong>: Save your formatted HTML code directly to your device for offline access.</li>
      <li><strong>Responsive Design</strong>: Optimized for all devices, allowing easy access from desktop and mobile.</li>
    </ul>

    <h3 className="text-xl font-semibold mt-6 mb-4">Benefits of Using HTML Formatter</h3>
    <ul className="list-disc list-inside mb-4">
      <li><strong>Improved Readability</strong>: Well-formatted code is easier to read and understand, which is essential for collaboration.</li>
      <li><strong>Time-Saving</strong>: Automating the formatting process saves you time, allowing you to focus on coding.</li>
      <li><strong>Code Consistency</strong>: Maintain consistency across your projects, making it easier for others to work with your code.</li>
      <li><strong>Enhanced Debugging</strong>: Clean and structured code helps identify errors and bugs more efficiently.</li>
    </ul>

    <h3 className="text-xl font-semibold mt-6 mb-4">Get Started</h3>
    <p>
      Ready to clean up your HTML code? <a title="Try our free html formatter" href="#hero">Try our free html formatter</a> today and experience the difference in your coding workflow!
    </p>
  </div>
</section>
<HomeFeaturesSection/>  
     </>
  );
};

export default HTMLFormatter;
