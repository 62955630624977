// src/components/WhyChoose.js
import { FaUserFriends, FaRocket, FaMoneyBillAlt } from 'react-icons/fa';  
import Star from '../../Images/star.png';

const WhyChoose = () => {

    const features = [
        {
          icon: <FaUserFriends className="h-8 w-8 text-secondary mx-auto" />,
          title: "User-Friendly Interface",
          description: "Our tools are designed with simplicity in mind, allowing you to achieve results effortlessly.",
        },
        {
          icon: <FaRocket className="h-8 w-8 text-secondary mx-auto" />,
          title: "Fast Processing",
          description: "Experience quick and efficient processing for all your tasks, enhancing your productivity.",
        },
        {
          icon: <FaMoneyBillAlt className="h-8 w-8 text-secondary mx-auto" />,
          title: "100% Free Services",
          description: "Access all our tools completely free of charge—no hidden fees or subscriptions.",
        },
      ];


    return (
        <>  
<section className="why-choose py-20 bg-gray-50">

<div className="container mx-auto">
        <div className="w-full text-center mb-16">
          <div className='text-center w-full'>
              <img src={Star} width={24} height={24} className='mx-auto' alt='Why Choose Our Tools?' />
          </div>
          <h2 className="font-bold text-gray-700 mb-6">
          Why Choose Our Tools?
          </h2> 
        </div>
        </div>
      <div className="container mx-auto p-5"> 
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <div key={index} className="bg-white shadow-md rounded-lg p-6 text-center transition-transform transform hover:scale-105">
              {feature.icon}
              <h3 className="text-lg font-semibold text-gray-700 mt-4 mb-2">{feature.title}</h3>
              <p className="text-gray-500 mb-4">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
    </>
    );
};

export default WhyChoose;
