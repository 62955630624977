import React from 'react'; 
import Star from '../../Images/star.png';
import FaEye from '../../Images/Our-vision.svg';
import FaHeart from '../../Images/our-values.svg'; 
import FaRocket from '../../Images/our-mission.svg';
import FaUsers from '../../Images/our-team.svg';
import FaLightbulb from '../../Images/Innovation.svg';
import FaHandsHelping from '../../Images/community.svg';

const AboutUs = () => {
  const features = [
    {
      title: "Our Vision",
      description: "We envision a world where web development tools are accessible, fast, and reliable, empowering developers and designers with high-quality resources for exceptional web experiences.",
      icon: FaEye
    },
    {
      title: "Our Values",
      description: "We prioritize innovation, simplicity, and user-centered design in all our tools, catering to both novice and expert developers for a seamless coding experience.",
      icon: FaHeart
    },
    {
      title: "Our Mission",
      description: "Our mission is to provide efficient web development tools that streamline workflows, enabling you to work faster and achieve more with every project.",
      icon: FaRocket
    },
    {
      title: "Our Team",
      description: "Our team of dedicated developers and designers is passionate about crafting tools that enhance productivity and make a meaningful impact in web development.",
      icon: FaUsers
    },
    {
      title: "Innovation",
      description: "We stay at the cutting edge by continuously exploring new technologies, ensuring that our tools incorporate the latest innovations in web development.",
      icon: FaLightbulb
    },
    {
      title: "Community",
      description: "Community feedback shapes our tools. We actively engage with users to develop features that genuinely benefit the web development community.",
      icon: FaHandsHelping
    }
];


  return (
    <section className="bg-gradient-to-br from-blue-50 to-blue-100 py-20"> 
      <div className="container text-center mb-16">
      <div className='text-center w-full'>
    <img src={Star} width={24} height={24} className='mx-auto' alt='Key Features of Tools for Web Developers' />
</div>
<h2 className="font-bold text-gray-700 mb-6">
    About Us
</h2>
<p className="text-gray-600">
    Welcome to our Tools For Dev project! We are dedicated to providing user-friendly and efficient web development tools for developers and designers alike. Our mission is to streamline your workflow and boost productivity, making it easy to access all the essential tools in one place.
</p>
</div>
      
      <div className="flex flex-col lg:flex-row flex-wrap items-center justify-center gap-8">
        {features.map((feature, index) => (
          <div
            key={index}
            className="w-full lg:w-1/3 bg-white shadow-lg hover:shadow-xl transform hover:scale-105 transition-transform duration-300 rounded-lg p-6 text-center"
          >
            <div className="flex justify-center items-center text-primary mb-4">
              <img width={32} height={32} src={feature.icon} title={feature.title}  alt={feature.title} />
            </div>
            <h3 className="text-2xl font-semibold text-gray-800 mb-2">{feature.title}</h3>
            <p className="text-gray-600">{feature.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default AboutUs;