"use client";
import { useEffect } from "react";
import CSSFormatterTool from "../Components/css-formatter/css-formattertool"; 
import setMeta from "../setMeta";
import HomeFeaturesSection from "../Components/Home/features";

 
// Marking this component as a Client Component
 const CSSFormatter = () => { 

  useEffect(() => {
    setMeta('CSSFormatter'); // Set meta for this specific page
}, []);

  return (
     <>
    <CSSFormatterTool/>

    <section id="css-formatter" className="py-20 bg-gray-100 [&_a]:text-primary hover:[&_a]:text-secondary">
  <div className="container mx-auto">
    <h2 className="mb-6">CSS Formatter</h2>
    
    <p className="mb-4">
      Enhance the readability of your CSS files with our CSS Formatter tool. This tool organizes your stylesheets, making them easier to read and maintain, ensuring consistency in your code.
    </p>

    <h3 className="text-xl font-semibold mt-6 mb-4">How It Works</h3>
    <ol className="list-decimal list-inside mb-4">
      <li><strong>Input Your CSS Code</strong>: Paste your CSS code into the designated input area.</li>
      <li><strong>Format Options</strong>: Choose from various formatting options to customize how your CSS is structured, such as indentation styles and newline settings.</li>
      <li><strong>Download Your Formatted CSS</strong>: After formatting, download the organized CSS file with a single click.</li>
    </ol>

    <h3 className="text-xl font-semibold mt-6 mb-4">Key Features</h3>
    <ul className="list-disc list-inside mb-4">
      <li><strong>Multiple Formatting Options</strong>: Customize the formatting according to your preferences.</li>
      <li><strong>User-Friendly Interface</strong>: Easily paste or upload your CSS for quick formatting.</li>
      <li><strong>Fast Processing</strong>: Enjoy rapid formatting times, even for extensive CSS files.</li>
      <li><strong>Preview Before Download</strong>: Review your formatted CSS to ensure it meets your expectations before saving it.</li>
      <li><strong>Privacy Focused</strong>: Your data is processed securely and removed from our servers after formatting.</li>
    </ul>

    <h3 className="text-xl font-semibold mt-6 mb-4">Benefits of CSS Formatting</h3>
    <ul className="list-disc list-inside mb-4">
      <li><strong>Improved Readability</strong>: Well-structured CSS is easier to read, making maintenance simpler.</li>
      <li><strong>Consistency Across Styles</strong>: Ensure your CSS follows a consistent structure, improving overall code quality.</li>
      <li><strong>Enhanced Collaboration</strong>: Clean, organized code makes it easier for teams to collaborate on projects.</li>
    </ul>

    <h3 className="text-xl font-semibold mt-6 mb-4">Get Started</h3>
    <p>
      Ready to improve your CSS code quality? <a title="Try our CSS Formatter" href="#hero">Try our CSS Formatter</a> today and enhance your coding experience!
    </p>
  </div>
</section>
<HomeFeaturesSection/>  
     </>
  );
};

export default CSSFormatter;
