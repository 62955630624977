"use client";

import { useState } from "react";
import { useDropzone } from "react-dropzone";
import imageCompression from "browser-image-compression";
import JSZip from "jszip";
import { ReactComponent as DownloadIcon } from "../../Images/downloadIcon.svg";
import { BiLoader } from "react-icons/bi";

export default function WebPConverter() {
  const [convertedImages, setConvertedImages] = useState([]);

  const handleConvertToWebP = async (files) => {
    for (const file of files) {
      const fileSizeMB = (file.size / (1024 * 1024)).toFixed(2);
      setConvertedImages((prevState) => [
        ...prevState,
        {
          name: file.name,
          originalSize: fileSizeMB,
          newSize: null,
          status: 0,
          convertedUrl: null,
        },
      ]);

      try {
        // Step 1: Compress the original image before converting to WebP
        console.log(`Original file size: ${fileSizeMB} MB`);
        const compressedFile = await imageCompression(file, {
          maxSizeMB: 5, // Compress to a max of 5MB
          useWebWorker: true,
        });

        const compressedFileSizeMB = (compressedFile.size / (1024 * 1024)).toFixed(2);
        console.log(`Compressed original file size: ${compressedFileSizeMB} MB`);

        // Step 2: Convert the compressed image to WebP format
        const webpBlob = await imageCompression(compressedFile, {
          fileType: "image/webp", // Convert to WebP
          maxSizeMB: 2, // Ensure the final file is smaller than 2MB
          maxWidthOrHeight: 1024, // Resize to 1024px in either dimension
          useWebWorker: true,
          initialQuality: 0.5, // Set WebP quality to 70%
        });

        const webpBlobSizeMB = (webpBlob.size / (1024 * 1024)).toFixed(2);
        console.log(`WebP file size after compression: ${webpBlobSizeMB} MB`);

        // Create a URL for the converted WebP image
        const convertedUrl = URL.createObjectURL(webpBlob);
        const newSizeMB = (webpBlob.size / (1024 * 1024)).toFixed(2);

        // Update state with the converted image details
        setConvertedImages((prevState) => {
          const newState = [...prevState];
          const index = newState.findIndex((img) => img.name === file.name);
          if (index !== -1) {
            newState[index] = {
              ...newState[index],
              newSize: newSizeMB,
              status: 100,
              convertedUrl: convertedUrl,
            };
          }
          return newState;
        });
      } catch (error) {
        console.error("Error during WebP conversion or compression:", error);
      }
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: { "image/jpeg": [], "image/png": [], "image/gif": [] },
    maxFiles: 10,
    onDrop: handleConvertToWebP,
  });

  const handleDownloadAll = async () => {
    const zip = new JSZip();
    const folder = zip.folder("WebP Converted Images");

    for (const image of convertedImages) {
      if (image.convertedUrl) {
        const response = await fetch(image.convertedUrl);
        const blob = await response.blob();
        folder.file(image.name.replace(/\.[^/.]+$/, ".webp"), blob); // Replace extension with .webp
      }
    }

    zip.generateAsync({ type: "blob" }).then((content) => {
      const url = URL.createObjectURL(content);
      const a = document.createElement("a");
      a.href = url;
      a.download = "WebP_Converted_Images.zip";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    });
  };

  return (
    <section>
      <div className="container" id="hero">
        <div className="row">
          <div className="col w-full">
            <div className="pb-5 text-center my-16">
              <h1> webp conveter </h1>
              <div
                {...getRootProps()}
                className="p-10 border-2 rounded-2xl border-dashed border-highlight mb-10 flex flex-wrap items-center justify-center cursor-pointer font-semibold text-xl mt-10 min-h-80 text-secondary"
              >
                <input {...getInputProps()} />
                <p className="w-full">
                  Drag & drop up to 10 images here, or click to select files
                </p>
                <p className="w-full"> Or </p>
                <span className="primary-btn btn-large"> Upload Images </span>
              </div>

              <div>
                <div className="image-list-converted w-full">
                  {convertedImages.length > 0 && (
                    <h2 className="mb-5 inline-block">Converted Images</h2>
                  )}

                  {convertedImages.map((image, index) => (
                    <div className="image-list-converted-item mb-5" key={index}>
                      <div className="row">
                        <div className="col w-[100px]">
                          {image.convertedUrl ? (
                            <a href={image.convertedUrl} download={`${image.name.replace(/\.[^/.]+$/, ".webp")}`}>
                              <img
                                width={60}
                                height={60}
                                className="object-cover w-[60px] h-[60px]"
                                src={image.convertedUrl}
                                alt={image.name}
                              />
                            </a>
                          ) : (
                            <BiLoader />
                          )}
                        </div>

                        <div className="col w-[calc(100%_-_200px)]">
                          <div className="w-full text-left text-base">
                            <div className="w-full mb-1 inline-block text-gray-800 font-semibold">
                              {image.name}
                            </div>

                            <div className="w-full text-right mb-1">
                              <div className="bg-primary p-2 rounded-3xl" style={{ width: `${image.status}%` }}></div>
                            </div>

                            <div className="w-full">
                              <p>
                                {image.originalSize} MB to{" "}
                                {image.newSize ? image.newSize : "-"} MB
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="col w-[100px] flex flex-wrap items-center justify-center">
                          {image.convertedUrl ? (
                            <a
                              className="[&_svg]:w-6 [&_svg]:h-auto inline-block p-2"
                              href={image.convertedUrl}
                              download={`${image.name.replace(/\.[^/.]+$/, ".webp")}`}
                            >
                              <DownloadIcon />
                            </a>
                          ) : (
                            <span>Processing...</span>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                {convertedImages.length > 0 && (
                  <button onClick={handleDownloadAll} className="primary-btn mt-5 btn-large">
                    Download All as ZIP
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
