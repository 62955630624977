"use client"; // Marking this component as a Client Component

import React, { useState, useEffect } from 'react';
import { Loader } from '../Common/Loader';

const formatHTML = (html) => { // Correct function name here
  const indent = '  '; // Two spaces for indentation
  let formatted = '';
  let level = 0;

  const addLine = (line) => {
    formatted += `${indent.repeat(level)}${line}\n`;
  };

  html = html.replace(/>\s*</g, '><').trim();
  const regex = /<\/?([a-z][a-z0-9]*)\s*([^>]*)?>/gi;

  let lastIndex = 0;
  let match;

  while ((match = regex.exec(html)) !== null) {
    const [tag] = match;
    const start = match.index;

    const textBeforeTag = html.slice(lastIndex, start).trim();
    if (textBeforeTag) {
      addLine(textBeforeTag);
    }

    if (tag.startsWith('</')) {
      level--;
      addLine(tag);
    } else {
      addLine(tag);
      if (!tag.endsWith('/>')) {
        level++;
      }
    }

    lastIndex = regex.lastIndex;
  }

  const remainingText = html.slice(lastIndex).trim();
  if (remainingText) {
    addLine(remainingText);
  }

  return formatted.trim();
};

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(handler);
  }, [value, delay]);

  return debouncedValue;
};

const HTMLFormatter = () => {
  const [inputHtml, setInputHtml] = useState('');
  const [formattedHtml, setFormattedHtml] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const debouncedHtml = useDebounce(inputHtml, 500);

  useEffect(() => {
    if (debouncedHtml) {
      setIsLoading(true);
      setError(''); // Clear any previous errors

      try {
        const formatted = formatHTML(debouncedHtml); // Use the correct function name here
        setFormattedHtml(formatted);
      } catch (err) {
        console.error(err);
        setError('Error formatting HTML. Please check your input.');
        setFormattedHtml(''); // Clear the formatted HTML on error
      } finally {
        setIsLoading(false); // Ensure loading state is cleared
      }
    } else {
      setFormattedHtml(''); // Clear formatted HTML if input is empty
    }
  }, [debouncedHtml]);

  const handleClear = () => {
    setInputHtml('');
    setFormattedHtml('');
    setError('');
  };

  const handleCopy = () => {
    if (formattedHtml) {
      navigator.clipboard.writeText(formattedHtml)
        .then(() => {
          setShowAlert(true);
          setTimeout(() => setShowAlert(false), 2000); // Hide after 2 seconds
        })
        .catch((err) => {
          console.error('Failed to copy text:', err);
        });
    }
  };

  return (
    <section className='w-full' id='hero'>
    <div className="container">
      <div className="p-4 my-10">
        <div className='row'>
          <div className='lg:w-6/12 w-full px-5'>
            <h1 className="font-bold mb-10">HTML Formatter</h1>
            <textarea
              rows={10}
              className="w-full p-2 border border-gray-300 rounded-md mb-4 focus:outline-none focus:ring-2 focus:ring-offset-gray-800 min-h-[500px]"
              value={inputHtml}
              onChange={(e) => setInputHtml(e.target.value)}
              placeholder="Enter HTML code"
            />
            {error && <p className="text-[red] mt-2">{error}</p>}
            <button onClick={handleClear} className="primary-btn mt-5 btn-large">
              Clear
            </button>
          </div>

          <div className='lg:w-6/12 w-full px-5'>
            <h3 className="lg:mt-0 mt-10 mb-10 h2">Formatted HTML</h3>
            {isLoading ? <Loader /> : (
              <>
                <textarea
                  rows={10}
                  className="w-full p-2 border border-gray-300 rounded-md mb-4 focus:outline-none focus:ring-2 focus:ring-offset-gray-800 min-h-[500px]"
                  value={formattedHtml}
                  readOnly
                  placeholder="Formatted HTML will appear here..."
                />
                <button onClick={handleCopy} className="primary-btn mt-5 btn-large">
                  Copy
                </button>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Copy Alert */}
      {showAlert && (
        <div className="fixed bottom-4 right-4 bg-gray-900 text-white px-4 py-2 rounded shadow-lg transition-opacity duration-500 ease-out opacity-100 animate-fade-out">
          Formatted HTML copied to clipboard!
        </div>
      )}
    </div>
    </section>
  );
};

export default HTMLFormatter; // Ensure this matches the component name
