"use client";
import { useEffect } from "react"; 
import setMeta from "../setMeta";
import HomeFeaturesSection from "../Components/Home/features";
import JavascriptFormatterTool from "../Components/javascript-formatter/javascript-formatter";
// Marking this component as a Client Component
 const JavascriptFormatter = () => { 
  useEffect(() => {
    setMeta('javascriptFormatter'); // Set meta for this specific page
}, []);
  return (
     <>
    <JavascriptFormatterTool/>
    <section id="javascript-formatter" className="py-20 bg-gray-100 [&_a]:text-primary hover:[&_a]:text-secondary">
    <div className="container mx-auto">
    <h2 className="mb-6">JavaScript Formatter</h2>
    
    <p className="mb-4">
      Clean and format your JavaScript code effortlessly with our JavaScript Formatter tool. This tool organizes your code, making it easier to read, debug, and maintain. Perfect for developers who want to improve code readability and consistency.
    </p>

    <h3 className="text-xl font-semibold mt-6 mb-4">How It Works</h3>
    <ol className="list-decimal list-inside mb-4">
      <li><strong>Enter Your JavaScript Code</strong>: Paste your JavaScript code into the input area.</li>
      <li><strong>Choose Formatting Options</strong>: Select from various options such as indentation, line breaks, and brace positioning to customize the output.</li>
      <li><strong>View the Formatted Output</strong>: See your neatly organized JavaScript code and copy it with a single click.</li>
    </ol>

    <h3 className="text-xl font-semibold mt-6 mb-4">Key Features</h3>
    <ul className="list-disc list-inside mb-4">
      <li><strong>Real-time Formatting</strong>: Instantly see the changes as you input your JavaScript code.</li>
      <li><strong>Customizable Settings</strong>: Adjust formatting preferences like indentation and spacing for a personalized style.</li>
      <li><strong>Syntax Highlighting</strong>: Enhance readability with clear syntax highlighting.</li>
      <li><strong>Download Option</strong>: Save your formatted JavaScript code directly to your device for easy access.</li>
      <li><strong>Responsive Design</strong>: Optimized for all devices, allowing easy access from both desktop and mobile.</li>
    </ul>

    <h3 className="text-xl font-semibold mt-6 mb-4">Benefits of Using JavaScript Formatter</h3>
    <ul className="list-disc list-inside mb-4">
      <li><strong>Improved Readability</strong>: Well-structured code is easier to understand, essential for debugging and collaboration.</li>
      <li><strong>Time-Saving</strong>: Automating the formatting process frees up time for writing code.</li>
      <li><strong>Code Consistency</strong>: Ensure uniform code style across your projects, simplifying collaboration.</li>
      <li><strong>Enhanced Debugging</strong>: Clean, structured code helps quickly identify and fix errors.</li>
    </ul>

    <h3 className="text-xl font-semibold mt-6 mb-4">Get Started</h3>
    <p>
      Ready to clean up your HTML code? <a title="Try our javascript Formatter" href="#hero">Try our Javascript Formatter</a> today and experience the difference in your coding workflow!
    </p>
  </div>
</section>
<HomeFeaturesSection/>  
     </>
  );
};

export default JavascriptFormatter;
